import React from "react";
import "./Footer.css";
import { AiFillFacebook, AiFillInstagram, AiFillGithub } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div className="footerContent">
                <div>
                    <div className="logo_footer_div">
                        <img
                            src={require("../../../assets/images/Overdrivelogo.png")}
                            alt="logo"
                            className="logo_footer"
                        />
                    </div>
                    <p className="text">
                        ©2022 Overdrive™
                    </p>
                    <div className="social_links">
                        <a href="https://www.facebook.com/Overdrive-Number-Plates-101512529215072">
                            <AiFillFacebook size={"50"} />
                        </a>
                        <a href="https://www.instagram.com/overdriveregistrations/">
                            <AiFillInstagram size={"50"} />
                        </a>
                    </div>
                </div>
                <div className="text">
                    <h3>Site Map</h3>
                    <Link to="/products">products</Link>
                </div>
                <br />
                <address>
                    <p>Contact us.</p>
                    <a href="tel:07967 111110">07967 111110</a>
                    <br />
                    <a href="mailto:bav@overdrivetech.co.uk">
                        bav@overdrivetech.co.uk
                    </a>
                    <br />
                </address>
                <div className="logo_footer_div_sub">
                    <img
                        src={require("../../../assets/images/dvla-logo.png")}
                        alt="dvla"
                        className="logo_footer"
                    />
                </div>
            </div>
            <hr />
            <div className="footer_footer">
                <p className="text">
                    We are DVLA registered and comply with british standards
                </p>
                <p className="text">BS AU 145e</p>
                <p className="text">
                    Proof of address (driving licence or utility bill etc.) and
                    vehicle entitlement documentation (V5C or V5C/2 etc.)
                    required for all road legal registration plates.
                </p>
            </div>
            <hr />
            <div className="text">
                <a href="https://github.com/DanApollo">
                <AiFillGithub size={"30"} />
            </a>
            </div>
        </footer>
    );
}
