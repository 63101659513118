import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom"
import './App.css';
import ScrollToTop from "./views/components/ScrollToTop/ScrollToTop";
import Layout from './views/layout/Layout/Layout';
import Hero from "./views/pages/Hero/Hero"
import Products from "./views/pages/Products/Products"
import Product from "./views/pages/Product/Product";

export default function App() {
  const [products, setProducts] = useState([])
  useEffect(() => {
    fetch(process.env.REACT_APP_DB_URL)
    .then(res => {
      if (res.ok) {
        return res.json()
      }
      throw res
    })
    .then(res => {
      setProducts(res)
    })
    .catch(console.error)
  }, []);
  return (
    <Layout>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Hero />}></Route>
        <Route path="/products" element={<Products products={products}/>}></Route>
        <Route path="/products/:id" element={<Product products={products}/>}></Route>
        <Route path="/*" element={<div>404</div>}></Route>
      </Routes>
    </Layout>
  );
}