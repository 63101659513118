import React from "react";
import { FaShareAlt } from "react-icons/fa"
import { useParams } from "react-router-dom";
import "./Product.css"

function CustomImage({ product }) {
    let image_path = "";
    try {
        image_path = require(`../../../assets${product.image}/1.jpeg`);
    } catch {
        image_path = require(`../../../assets/images/default.png`);
    }
    return <img className="product_page_image" src={image_path} alt={product.name} />;
}

export default function Product({ products }) {
    const { id } = useParams();
    const product = products.find(element => element.id === parseInt(id))
    return (
        <MobileView product={product} />
    );
}

function MobileView({ product }) {
    return (
        <div className="mobile_card">
            <div className="mobile_image_container">
                <CustomImage product={product}/>
                <div className="mobile_share">
                    <p><FaShareAlt size={30}/></p>
                </div>
            </div>
            <div className="mobile_card_info">
                <div className="mobile_title">
                    <span><h3>{product.name}</h3></span>
                </div>
                <div className="mobile_product_info">
                    <p>{product.description}</p>
                </div>
                <div className="mobile_price">
                    <p>Single : £{product.price}</p>
                    {product.price2 && <p>Pair : £{product.price2}</p>}
                </div>
            </div>
        </div>
    )
}