import React from "react";
import { Link } from "react-router-dom";
import Slider from "../../components/Slider/Slider";
import "./Hero.css";

export default function Hero() {
    return (
        <div className="grid_box">
            <div className="carousel_hero">
                <Slider/>
            </div>
            <div className="carousel_gradient">
                <div className="hero_text_box">
                    <div className="hero_text">
                        <h1>Overdrive Plates</h1>
                        <Link
                            to={"/products"}
                        >
                            <button className="prices_button">
                                view products
                            </button>
                        </Link>
                        <p>
                            FREE LOCAL DELIVERY
                            <br />
                            FREE SHIPPING UK ONLY
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
