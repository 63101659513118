import React, { useState, useEffect, useLayoutEffect } from "react";
import "./Header.css";
import { useLocation, Link } from "react-router-dom";

export default function Header() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
    }, []);
    useEffect(() => {
        setIsMenu(false);
    }, [size]);
    const [isMenu, setIsMenu] = useState(false);
    const menuClass = `menu-btn ${isMenu ? "open" : ""}`;
    const rightSideId = isMenu ? "hidden" : "";
    const location = useLocation()
    return (
        <header className={rightSideId}>
            <nav>
                <ul className="main_ul">
                    <li className="logo_item">
                        <Link onClick={() => {setIsMenu(false)}} to="/">
                            <img
                                className="logo"
                                src={require("../../../assets/images/Overdrivelogo.png")}
                                alt="logo"
                            />
                        </Link>
                    </li>
                    <ul className="nav_links" id={rightSideId}>
                        <li  >
                            <Link {...(location.pathname === "/products" && {className: "active_link"})} onClick={() => {setIsMenu(false)}} to="/products">
                                Products
                            </Link>
                        </li>
                    </ul>
                    {size[0] <= 1169 && <li
                        className={menuClass}
                        onClick={() => setIsMenu(!isMenu)}
                    >
                        <div className="menu-btn-burger"></div>
                    </li>}
                </ul>
            </nav>
        </header>
    );
}
