import React from "react";
import Card from "../../components/Card/Card";
import "./Products.css";

export default function Prices({
    products
}) {
    return (
        <div className="cards">
            {products.map((product, index) => <Card product={product} key={index}/>)}
        </div>
    );
}
