import React from "react";
import { Link } from "react-router-dom";
import "./Card.css";

function CustomImage({ product }) {
    let image_path = "";
    try {
        image_path = require(`../../../assets${product.image}/1.jpeg`);
    } catch {
        image_path = require(`../../../assets/images/default.png`);
    }
    return <img className="card_img" src={image_path} alt={product.name} />;
}
export default function Card({
  product
}) {
    return (
        <Link to={`/products/${product.id}`}>
            <div className="card">
                <div className="card_info">
                    <span className="product_title"><h3>{product.name}</h3></span>
                    <p>{product.description}</p>
                    <p>Single : £{product.price}</p>
                    {product.price2 && <p>Pair : £{product.price2}</p>}
                </div>
                <div className="card_img_container">
                    <CustomImage product={product} />
                </div>
            </div>
        </Link>
    );
}
