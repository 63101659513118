import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import './Slider.css'

  
const AutoplaySlider = withAutoplay(AwesomeSlider);
export default function Slider() {
  return (
      <AutoplaySlider play cancelOnInteraction={false} interval={8000}>
        {/* <div className='carousel_1'><img className="carousel_img" src={require("../../assets/images/productPhotos/4DUK/1.jpeg")} alt="carousel_photo" /></div>
        <div className='carousel_1'><img className="carousel_img" src={require("../../assets/images/productPhotos/4DUK/3.jpeg")} alt="carousel_photo" /></div>
        <div className='carousel_1'><img className="carousel_img" src={require("../../assets/images/productPhotos/4DUK/4.jpeg")} alt="carousel_photo" /></div>
        <div className='carousel_1'><img className="carousel_img" src={require("../../assets/images/productPhotos/UKoblong/1.jpeg")} alt="carousel_photo" /></div>
        <div className='carousel_1'><img className="carousel_img" src={require("../../assets/images/productPhotos/UKoblong/2.jpeg")} alt="carousel_photo" /></div> */}
        <div className='carousel_1' style={{background: `url(${require("../../../assets/images/productPhotos/4DUK/1.jpeg")}) left top`}}></div>
        {/* <div className='carousel_1' style={{background: `url(${require("../../assets/images/productPhotos/4DUK/3.jpeg")}) left top`}}></div> */}
        <div className='carousel_1'><img className="carousel_img" src={require("../../../assets/images/productPhotos/4DUK/1.jpeg")} alt="carousel_photo" /></div>
      </AutoplaySlider>
  );
}